import React from 'react';
import './Services.css'; 

const Services = () => {
  return (
    <section id="section3" className="services-section">
      <div className="services-container">
        <h1>Hizmetlerimiz</h1>
  

        <div className="services-grid">
          <div className="service-item">
            <img src={process.env.PUBLIC_URL + '/image13.png'} alt="Service 1" />
            <h5>→ Ölçülebilir, yüksek bütünlüklü sonuçlar elde ettiğimiz projelere liderlik ediyoruz.</h5>
          </div>
          <div className="service-item">
            <img src={process.env.PUBLIC_URL + '/image4.png'} alt="Service 2" />
            <h5>→ Hükümetler, işletmeler ve sivil toplum kuruluşlarıyla iş birliği yapıyoruz.</h5>
          </div>
          <div className="service-item">
            <img src={process.env.PUBLIC_URL + '/image5.png'} alt="Service 3" />
            <h5>→ Kalkınma ajansı projeleri ve yatırım teşvikleri ile ilgili danışmanlık veriyoruz.</h5>
          </div>
          <div className="service-item">
            <img src={process.env.PUBLIC_URL + '/image6.png'} alt="Service 4" />
            <h5>→ Verra sertifikalandırma projelerinde danışmanlık sağlıyoruz.</h5>
          </div>
          <div className="service-item">
            <img src={process.env.PUBLIC_URL + '/image7.png'} alt="Service 5" />
            <h5>→ Karbon azaltımı ve sürdürülebilirlik çalışmalarında çözümler geliştiriyoruz.</h5>
          </div>
          <div className="service-item">
            <img src={process.env.PUBLIC_URL + '/image2.png'} alt="Service 6" />
            <h5>→ Ar-Ge ve Ür-Ge destekleri konusunda danışmanlık sunuyoruz.</h5>
          </div>
          <div className="service-item">
            <img src={process.env.PUBLIC_URL + '/image9.png'} alt="Service 7" />
            <h5>→ KOSGEB ve TÜBİTAK projelerine yönelik rehberlik sağlıyoruz.</h5>
          </div>
          <div className="service-item">
            <img src={process.env.PUBLIC_URL + '/image10.png'} alt="Service 8" />
            <h5>→ Atık yönetimi süreçlerinin dijitalleşmesine yönelik projeler geliştiriyor ve teknoloji çözümleri sunuyoruz</h5>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;

