import React from 'react';
import './Certificates.css';

// Description texts as separate variables
const iso27001Description = `
İlker Teknik olarak, müşterilerimize sunduğumuz hizmetlerde bilgi güvenliğine büyük önem veriyoruz. Bu kapsamda, ISO 27001:2022 Bilgi Güvenliği Yönetim Sistemi sertifikamızı yenileyerek, bilgi güvenliğini sağlamak ve kritik verilerin korunması için gerekli tüm standartlara uygun bir yönetim sistemi oluşturduğumuzu bir kez daha kanıtladık. Bu sertifika, atık yönetimi yazılım çözümlerinden GPS tabanlı izleme sistemlerine kadar geniş bir hizmet yelpazesinde bilgi varlıklarının korunmasını garanti altına almaktadır.
`;

const iso9001Description = `
İlker Teknik olarak, müşteri memnuniyeti ve hizmet kalitesini her zaman ön planda tutuyoruz. ISO 9001:2015 Kalite Yönetim Sistemi sertifikamız, sunduğumuz hizmetlerin uluslararası kalite standartlarına uygun olduğunu ve sürekli iyileştirme odaklı bir yönetim anlayışına sahip olduğumuzu göstermektedir. Mobil uygulama tasarımından atık yönetimi çözümlerine kadar, tüm süreçlerimizde yüksek kalite standartlarına bağlı kalarak, müşterilerimize en iyi hizmeti sunmaya devam ediyoruz.
`;

const certificates = [
  {
    id: 1,
    title: 'ISO 27001:2022 Bilgi Güvenliği Yönetim Sistemi Sertifikası',
    description: iso27001Description,
    image: process.env.PUBLIC_URL + '/certificate1.png',
  },
  {
    id: 2,
    title: 'ISO 9001:2015 Kalite Yönetim Sistemi Sertifikası',
    description: iso9001Description,
    image: process.env.PUBLIC_URL + '/certificate2.png',
  }
];

const Certificates = () => {
  return (
    <section id="section6" className="certificates-section">
      <div className="certificates-container">
        <h1>Sertifikalarımız</h1>
        <div className="certificates-grid">
          {certificates.map(certificate => (
            <div key={certificate.id} className="certificate-item">
              <img src={certificate.image} alt={certificate.title} />
              <h3>{certificate.title}</h3>
              <p className="certificate-description">{certificate.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Certificates;

