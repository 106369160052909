import React, { useState } from 'react';
import './AboutUs.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

// Long text content
const aboutUsText = `
2017 yılından bu yana danışmanlık ve finans sektörlerinde edindiğimiz profesyonel deneyimle, alanında uzman mühendis ve danışman ekibimize, hibe ve teşvik alım süreçlerinde ve sonrasındaki her aşamada yanınızdayız. Projelerinizin her adımında rehberlik ederek en yüksek verimliliği ve başarıyı sağlamayı hedefliyoruz.
`;

const moreAboutUsText = `
Sürdürülebilir ve çevre dostu çözümlerle, hem yerel hem de küresel ölçekte işletmelerin ve toplumların çevresel etkilerini en aza indiren öncü bir danışmanlık firmasıyız. Yenilikçi projelerimizle, ölçülebilir sonuçlar elde eden bir ekosistem yaratarak daha yeşil bir geleceğe katkı sağlamayı amaçlıyoruz. Müşterilerimize Ar-Ge ve Ür-Ge projelerinden karbon azaltımı ve sürdürülebilirlik çalışmalarına kadar geniş bir yelpazede uzman danışmanlık hizmetleri sunarak, çevresel ve ekonomik hedeflerine ulaşmalarını sağlıyoruz. Hükümetler, işletmeler ve sivil toplum kuruluşlarıyla iş birliği içinde çalışarak, yüksek bütünlüklü, ölçülebilir sonuçlar elde eden projeler üretiyor ve daha sürdürülebilir bir dünya inşa etmek için yenilikçi çözümler sunuyoruz. Firmamız, faaliyetlerimizi yürütürken Birleşmiş Milletler Sürdürülebilir Kalkınma Amaçları'na (BM SDG) sıkı sıkıya bağlı kalmayı taahhüt eder. Çevresel, sosyal ve ekonomik sürdürülebilirliği temel alarak BM'nin belirlediği hedeflerden, özellikle İklim Eylemi (SDG 13), Sorumlu Üretim ve Tüketim (SDG 12), Sanayi, Yenilikçilik ve Altyapı (SDG 9) ile Sürdürülebilir Şehirler ve Topluluklar (SDG 11) doğrultusunda çalışıyoruz.
`;

const policiesText = [
  'Çevresel etkileri en aza indirmek için karbon azaltımına yönelik çözümler geliştiriyoruz.',
  'Sürdürülebilir atık yönetimi ve dijitalleşme projeleri ile kaynakların verimli kullanımını destekliyoruz.',
  'Ar-Ge ve inovasyonu teşvik ederek, topluma ve iş dünyasına sürdürülebilir değerler katmayı amaçlıyoruz.',
  'Şeffaf, kapsayıcı ve etik iş süreçleriyle sürdürülebilirlik hedeflerine ulaşılmasına katkı sağlıyoruz.'
];

const futureText = `
Bu çerçevede, iş birliği yaptığımız tüm taraflarla sürdürülebilir bir gelecek için ortak bir vizyonla ilerliyor ve projelerimize bu prensipleri entegre ediyoruz.
`;

const AboutUs = () => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = (e) => {
    e.preventDefault(); 
    setShowMore(!showMore); 
  };  

  return (
    <section id="section2" className="hakkimizda-section">
      <div className="container">
        <div className="left-column">
          <h1>Hakkımızda</h1>
          <h2>Birlikte Atıkları Azaltalım, Gezegeni Kurtaralım!</h2>
          <p>{aboutUsText}</p>

          <p className={`faded-text ${showMore ? 'show-more' : ''}`}>
            {moreAboutUsText}
          </p>

          <a href="#" className="read-more" onClick={toggleShowMore}>
            {showMore ? 'Daha Az' : 'Hakkımızda'} <FontAwesomeIcon icon={faArrowRight} />
          </a>
        </div>

        <div className="right-column">
          <img src={process.env.PUBLIC_URL + '/image8.png'} alt="Recycling" />
        </div>
      </div>

      <div className="sub-section">
        <div className="sub-section-content">
          <div className="left-image">
            <img src={process.env.PUBLIC_URL + '/image1.png'} className="sub-section-image" />
          </div>

          <div className="right-text">
            <h2>Politikalarımız kapsamında</h2>
            <ul>
              {policiesText.map((policy, index) => (
                <li key={index}>{policy}</li>
              ))}
            </ul>
            <p>{futureText}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;

