import React from 'react';
import './Blog.css';

// Blog post descriptions
const blogPostDescription1 = `
İlker Teknik Danışmanlığında Türkiye’nin İlk VERRA Plastik Projesi Sunuldu

Atkasan‘ın proje savunucusu olduğu ve İlker Teknik danışmanlığında geliştirilen “TOGETHER FOR TOMORROW: COLLABORATIVE PLASTIC COLLECTION AND COMMUNITY EMPOWERMENT” isimli proje, Türkiye’nin VERRA organizasyonuna sunulan ilk plastik projesi olma özelliğini taşıyor. Proje, Plastic Waste Reduction Program kapsamında dünya çapında yazılan ilk 50 proje arasında yer alarak öne çıkmayı başardı.
`;

const blogPostDescription2 = `
Tanrıkulu Plastik’in “Green Steps” Projesi VERRA Plastik Atık Azaltımı Programı Kapsamında Sunuldu

Tanrıkulu Plastik’in proje savunucusu olduğu “Green Steps: Towards a Greener Future with Tanrıkulu” isimli proje, VERRA Plastik Atık Azaltım Programı (PWRP) kapsamında sunulan öncü projelerden biridir. Proje, Tanrıkulu Grup’un Kocaeli ve Sakarya illerindeki toplama ve geri dönüşüm faaliyetlerini kapsayan tesislerinde hayata geçirilecektir.

Plastik Atık Azaltım Programı (PWRP), sürdürülebilir kredilendirme sistemi sayesinde, tesislerin operasyonlarını genişletmesine ve daha fazla plastik atığın toplanıp geri dönüştürülmesine olanak tanır.

İlker Teknik olarak, plastik atık endüstrisinde sürdürülebilirlik çabalarına katkıda bulunmaktan büyük gurur duyuyoruz!
`;

const blogPostDescription3 = `
🔔 İlker Teknik Ailesinden Büyük Haber!

Gururla duyuruyoruz ki, Türkiye’yi temsilen TÜBİTAK tarafından Küresel Temiz Teknolojiler Girişimcilik Programı (Global Cleantech Innovation Programme - GCIP) yarı finaline seçildik! 🌍💡

UNIDO iş birliğiyle ve birçok önemli bakanlığın desteğiyle yürütülen bu prestijli programda, temiz teknoloji çözümlerimizi ulusal ve küresel pazarlara hazırlama fırsatı bulacağız. Bu büyük başarıya ulaşmamızda emeği geçen tüm takım arkadaşlarımıza teşekkür ediyoruz! 🙌

12-13-14 Ağustos 2024 tarihlerinde İstanbul’da düzenlenecek Ulusal Akademi etkinliğinde, inovatif projelerimizi daha da ileriye taşıma şansını yakalayacağız. 🚀

Takipte kalın, bu yolculukta sizleri de yanımızda görmekten mutluluk duyarız!
`;

const blogPostDescription4 = `
🌟 GCIP Türkiye 2024 Ulusal Akademisi’ne Katıldık! 🌟

İlker Teknik olarak, ATIKSOFT saha bilgi atık yönetim yazılımımızla GCIP Türkiye 2024 Hızlandırıcı Programı‘na katılmaktan gurur duyuyoruz. 12-14 Ağustos 2024 tarihlerinde gerçekleşen ve TÜBİTAK, NEOHUB ve Oxentia iş birliğiyle düzenlenen Ulusal Akademi’de, yerli ve yabancı eğitmenlerin rehberliğinde çeşitli İş Unsuru kapsamındaki eğitim ve mentorluk faaliyetlerine başladık. 

Bu başarıya ulaşmamızda emeği geçen tüm takım arkadaşlarımıza teşekkür ediyor, diğer girişimci arkadaşlarımıza da başarılar diliyoruz! 

#ATIKSOFT
`;

const blogPosts = [
  {
    id: 1,
    title: 'BLOG HABER NO: 1',
    description: blogPostDescription1,
    image: process.env.PUBLIC_URL + '/1.png'
  },
  {
    id: 2,
    title: 'BLOG HABER NO: 2',
    description: blogPostDescription2,
    image: process.env.PUBLIC_URL + '/2.png'
  },
  {
    id: 3,
    title: 'BLOG HABER NO: 3',
    description: blogPostDescription3,
    image: process.env.PUBLIC_URL + '/3.png'
  },
  {
    id: 4,
    title: 'BLOG HABER NO: 4',
    description: blogPostDescription4,
    image: process.env.PUBLIC_URL + '/4.png'
  }
];

const Blog = () => {
  return (
    <section id="section4" className="blog-section">
      <div className="blog-container">
        <h1>Blog</h1>
        <div className="blog-grid">
          {blogPosts.map(post => (
            <div key={post.id} className="blog-item">
              <img src={post.image} alt={post.title} />
              <p className="blog-date">{post.date}</p>
              <h3>{post.title}</h3>
              <p className="blog-description">{post.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Blog;

